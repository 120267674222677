<template>

  <surroundBox :name="description" desc="废弃物加工厂">
    <template slot="content">
      <div class="content-container">
        <div class="left">
          <highcharts :callback="loadData" :options="chartOptions" style="width: 100%;height: 100%"></highcharts>
        </div>
        <div class="right">
          <div v-for="(item, index) in dataSource" :key="index" class="item">
            <span class="title">{{ item.title }}</span>
            <span class="v">{{ item.num }}{{item.unit}}</span>
          </div>
        </div>
      </div>
    </template>
  </surroundBox>

</template>

<script>
import {Chart} from 'highcharts-vue'
import Highcharts from 'highcharts'
import exportingInit from 'highcharts/modules/exporting'
import highcharts3d from 'highcharts/highcharts-3d'
import surroundBox from "../surroundBox/surroundBox";

exportingInit(Highcharts)
highcharts3d(Highcharts)

export default {
  name: "recyclingUseServiceEntities",
  components: {
    highcharts: Chart,
    surroundBox,
  },
  data() {
    return {
      description: "资源化利用服务实体",
      chartOptions: {

        chart: {
          type: 'pie',
          options3d: {
            enabled: true,
            alpha: 45,//延Y轴旋转45度
            beta: 0
          },
          marginRight: 0,
          marginTop: 0,
          backgroundColor: 'rgba(0,0,0,0)',//将背景设置未透明
        },
        //去掉菜单
        exporting: {
          enabled: false,
          buttons: {
            exportButton: {
              enabled: false
            }
          }
        },
        title: {
          text: '',
          style: {
            "color": "#fff",
            "fontSize": "15px",
          },
          align: 'left',
        },
        tooltip: {
          enabled: false
          //pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        },
        plotOptions: {
          pie: {
            allowPointSelect: false,
            cursor: 'pointer',
            depth: 25,
            dataLabels: {
              enabled: true,
              distance: "-20%",
              format: '{point.y}',
              zIndex: 999,
              crop: false,
              color: '#fff',
            },
            showInLegend: true,
            center: ['50%', '40%'],
            colors: ['#7CB5EC', '#3EB4B0'],
          }
        },
        legend: {

          itemStyle: {
            fontSize: '14px',
            color: '#fff'
          },
          floating: true
        },

        series: [{
          type: 'pie',
          name: '',
          size: '100%',
          innerSize: '0%',
          data: []
        }],
      },
      dataSource: []
    }
  },
  methods: {

    loadData() {
      this.$post(this.$api.STATICTICS_MAP.PLATFORM_INFO, {
        resourceTypeId: 184
      }).then(res => {
        if (res && res.length > 0) {
          let d = []
          let total = 0; // 总数
          let num = 0; // 已经建设的数量
          res.forEach(item => {
            this.dataSource.push({
              title: item.remark,
              num: item.content,
              unit: item.contentUnit
            })

            total += item.content * 1
            num += item.fileUrl * 1 || 0
          })

          this.chartOptions.series[0].data = [
            {
              name: '规划建设',
              y: total
            },
            {
              name: '已建在建',
              y: num
            }
          ]
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>

.chart-box .content-container {
  width: 100%;
  height: 100%;
  display: flex;
  margin-top: 1.2vh;

  > div {
    flex: 1;
  }

  .left {
    /deep/ .highcharts-credits {
      display: none;

    }
  }

  .right {
    > div {
      width: 90%;
      padding: 0 0.14rem;
      height: 3.4vh;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:nth-child(odd) {
        background-color: rgba(66, 242, 255, .16);
      }

      .title, .v {
        font-size: 0.14rem;
      }

      .title {
        color: #DDDDDD;
      }

      .v {
        color: #00FFF4;
      }
    }
  }
}
</style>
